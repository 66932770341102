import Vue from 'vue';
import VueRouter from 'vue-router';
import MGuest from '@/middleware/guest';
import MCreatePassword from '@/middleware/createPassword';
import MAuth from '@/middleware/auth';
import MPasswordCreated from '@/middleware/passwordCreated';
import MNonAdminListingPartner from '@/middleware/nonAdminListingPartner';
import MAddListingNoLogin from '@/middleware/addListingNoLogin';
import VueGtm from '@gtm-support/vue2-gtm';

Vue.use(VueRouter);
function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `../views/${view}.vue`);
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: loadView('index'),
    meta: {
      middlewares: [MCreatePassword],
    },
  },
  {
    path: '/user/create-password',
    name: 'create-password',
    component: loadView('user/create-password'),
    meta: {
      middlewares: [MAuth, MPasswordCreated],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('login'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/register',
    name: 'register',
    component: loadView('register'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/confirm-partner-membership',
    name: 'confirm-partner-membership',
    component: loadView('confirm-partner-membership'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/forget-pass',
    name: 'forget-pass',
    component: loadView('forget-pass'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/otp',
    name: 'otp',
    component: loadView('otp'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/user/activation/:token',
    name: 'user-activation',
    component: loadView('user/activation/_token'),
  },
  {
    path: '/password/reset/:token',
    name: 'reset-password',
    component: loadView('password/reset/_token'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/dashboard/mylisting',
    name: 'my-listing',
    component: loadView('dashboard/mylisting/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/listing/add',
    name: 'add-listing',
    component: loadView('listing/add/_listingType/index'),
    meta: {
      middlewares: [MNonAdminListingPartner],
      // middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/listing/add/no-login',
    name: 'add-listing-no-login',
    component: loadView('listing/no-login'),
    meta: {
      middlewares: [MAddListingNoLogin],
    },
  },
  {
    path: '/listing/add/:listingType',
    name: 'add-listing-params',
    component: loadView('listing/add/_listingType/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/listing/edit/:uuid',
    name: 'edit-listing',
    component: loadView('listing/edit/_uuid/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/dashboard/mybooking',
    name: 'my-booking',
    component: loadView('dashboard/mybooking/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/mybooking/detail',
    name: 'mybooking-detail',
    component: loadView('mybooking/detail/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/dashboard/favorites',
    name: 'favorites',
    component: loadView('dashboard/favorites/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/detail/:hashId/:title',
    name: 'listing-detail',
    component: loadView('detail/_hashId/_title/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/request-to-book',
    name: 'request-to-book',
    component: loadView('request-to-book'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/booking/how-to-pay/:uuid',
    name: 'how-to-pay',
    component: loadView('booking/how-to-pay/_uuid/index'),
    meta: {
      middlewares: [MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/booking/sign/:uuid',
    name: 'sign',
    component: loadView('booking/sign/_uuid/index'),
    meta: {
      middlewares: [MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: loadView('invoice'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/booking/sign-fkp/:uuid',
    name: 'sign',
    component: loadView('booking/sign-fkp/_uuid/index'),
    meta: {
      middlewares: [MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/transaction/pay/:orderNumber',
    name: 'transaction-pay',
    component: loadView('transaction/pay/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/transaction/checkout',
    name: 'transaction-checkout',
    component: loadView('transaction/checkout/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/promo',
    name: 'promo',
    component: loadView('promo/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/promo/detail/:uuid',
    name: 'promo-detail',
    component: loadView('promo/detail/_uuid/index'),
    meta: {
      middlewares: [MCreatePassword],
    },
  },
  {
    path: '/:listing_type/search',
    name: 'search',
    component: loadView('_listingType/search/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/:listing_type/search/:propertyType',
    name: 'search',
    component: loadView('_listingType/search/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/:listing_type/search/:propertyType/:query',
    name: 'search',
    component: loadView('_listingType/search/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/user/listing/:userUuid',
    name: 'search-user-listing',
    component: loadView('_listingType/search/index'),
    meta: {
      middlewares: [MNonAdminListingPartner],
    },
  },
  {
    path: '/directory/:propertyType',
    name: 'directory',
    component: loadView('directory/index'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: loadView('notifications/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: loadView('inbox/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/inbox/:uuid',
    name: 'inbox-detail',
    component: loadView('inbox/_uuid/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: loadView('profile/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/ajukan-cicilan',
    name: 'ajukan-cicilan',
    component: loadView('ajukan-cicilan/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: loadView('change-password/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/bank-account',
    name: 'bank-account',
    component: loadView('bank-account/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminListingPartner],
    },
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: loadView('about-us'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/space-manager',
    name: 'space-manager',
    component: loadView('space-manager/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/co-renting',
    name: 'co-renting',
    component: loadView('co-renting/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/corporate-client',
    name: 'corporate-client',
    component: loadView('corporate-client/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/official-partner',
    name: 'official-partner',
    component: loadView('official-partner/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/rentfix-open-api',
    name: 'rentfix-open-api',
    component: loadView('rentfix-open-api/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: loadView('contact-us'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/help',
    name: 'help-index',
    component: loadView('help/_type/index'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/help/:type',
    name: 'help',
    component: loadView('help/_type/index'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/career',
    name: 'career',
    component: loadView('career'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: loadView('privacy-policy'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/term-of-service',
    name: 'term-of-service',
    component: loadView('term-of-service'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/project-partner/:hashId/:title',
    name: 'project-partner',
    component: loadView('project-partner/_hashId/_title/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminListingPartner],
    },
  },
  {
    path: '/listing-deals',
    name: 'listing-deals',
    component: loadView('listing-deals'),
    meta: {
      middlewares: [MCreatePassword],
    },
  },

  // Official Partner URL harus ditaruh paling bawah, kalau tidak nanti override URL page lain.
  {
    path: '/:partnerUrl/:areaUrl',
    name: 'official-partner',
    component: loadView('official-partner/_partnerUrl/_areaUrl/index'),
    meta: {
      middlewares: [MCreatePassword],
    },
  },
  {
    path: '/:partnerUrl',
    name: 'official-partner',
    component: loadView('official-partner/_partnerUrl/index'),
    meta: {
      middlewares: [MCreatePassword],
    },
  },
  { path: '*', component: loadView('404') },
];

export default () => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else if (to.hash) {
        return {
          selector: to.hash,
        };
      } else {
        return { x: 0, y: 0 };
      }
    },
  });
  if (process.env.NODE_ENV === 'production') {
    Vue.use(VueGtm, {
      id: 'GTM-58FTBVQ',
      vueRouter: router,
      debug: false,
    });
  }
  // // ############### Gtag Manager Installation ############
  // const VueGtag = require('vue-gtag');
  // Vue.use(
  //   VueGtag,
  //   {
  //     config: {
  //       id: 'G-6Q8W5XL6HH',
  //     },
  //     // router,
  //   },
  //   router,
  // );
  // // #################################################
  return router;
};
